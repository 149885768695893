import { useEffect } from "react";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { collectionPaths } from "../collectionPaths";
import { useState } from "react";
import { ISession } from "../../types";
import { convertSessionSnapToISession } from "../firestore-doc-conversion/convertSessionSnapToISession";

export const useSessionSubscription = (sessionId: string) => {
  const [session, setSession] = useState<ISession | undefined>();
  const db = getFirestore();

  useEffect(() => {
    let unsubscribe = () => {};

    if (sessionId) {
      unsubscribe = onSnapshot(
        doc(db, collectionPaths.sessions, sessionId),
        (snapshot) => {
          console.log("useSessionSubscription.onSnapshot", snapshot.data());
          if (snapshot.exists()) {
            setSession(
              convertSessionSnapToISession(sessionId, snapshot.data())
            );
          }
        }
      );
    }

    return unsubscribe;
  }, [db, sessionId]);

  return { session };
};
