import "./index.css";
import "semantic-ui-css/semantic.min.css";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/createRouter";
import { firebaseConfig } from "./firebaseConfig";
import { useUserAuth } from "./auth/useUserAuth";
import { GenericLoaderPage } from "./components/GenericLoaderPage";

initializeApp(firebaseConfig);

let attemptingAnonSignIn = false;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    },
  },
});

const AnonAuthAwaiter: React.FC<{}> = (props) => {
  const { user, anonSignIn } = useUserAuth();
  useEffect(() => {
    if (!user && !attemptingAnonSignIn) {
      attemptingAnonSignIn = true;
      anonSignIn();
    }
  }, [user, anonSignIn]);

  if (!user) {
    return <GenericLoaderPage />;
  }

  return <RouterProvider router={router} />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AnonAuthAwaiter />
    </QueryClientProvider>
  </React.StrictMode>
);
