import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Icon, Label } from "semantic-ui-react";
import { sessionPath } from "../../navigation/routePaths";
import { useCreateSessionMutation } from "../../services/mutations/useCreateSessionMutation";
import { PageLayout } from "../common/PageLayout";
import { PageHeader } from "../common/PageHeader";
import { strings } from "../../localization/strings";
import { cuisineTags, dietTags } from "../../constants";

interface INewSessionPageProps {}

export const NewSessionPage: React.FC<INewSessionPageProps> = (props) => {
  const [numOfParticipantsInput, setNumOfParticipantsInput] =
    useState<number>(2);
  const [dietTagsInput, setDietTagsInput] = useState<string[]>([]);
  const [cuisineTagInput, setCuisineTagInput] = useState<string | undefined>();
  const [isCreatingSession, setIsCreatingSession] = useState<boolean>(false);
  const { mutate: createSession } = useCreateSessionMutation();
  const navigate = useNavigate();

  const onCreateSession = () => {
    setIsCreatingSession(true);

    const tagsToUse = dietTagsInput.concat(
      !!cuisineTagInput ? [cuisineTagInput] : []
    );

    createSession(
      { numOfParticipants: numOfParticipantsInput, tags: tagsToUse },
      {
        onSuccess: (newSessionId) =>
          navigate(sessionPath.generateDynamicPath(newSessionId)),
      }
    );
  };

  const onAddDietTag = (tag: string) => {
    setDietTagsInput(dietTagsInput.concat([tag]));
  };

  const onRemoveDietTag = (tag: string) => {
    setDietTagsInput(dietTagsInput.filter((t) => t !== tag));
  };

  const onSelectCuisineTag = (tag: string) => {
    if (tag === cuisineTagInput) {
      setCuisineTagInput(undefined);
    } else {
      setCuisineTagInput(tag);
    }
  };

  const onIncreaseNumOfParticipants = () => {
    if (numOfParticipantsInput >= 10) {
      return;
    }

    setNumOfParticipantsInput(numOfParticipantsInput + 1);
  };

  const onDecreaseNumOfParticipants = () => {
    if (numOfParticipantsInput <= 1) {
      return;
    }

    setNumOfParticipantsInput(numOfParticipantsInput - 1);
  };

  return (
    <PageLayout>
      <PageHeader title={strings.newSessionHeader.text()} enableGoBack={true} />
      <Form>
        <Form.Field>
          <label>{strings.newSessionNumOfParticipantsInputLabel.text()}</label>
          <Button.Group>
            <Button icon onClick={onDecreaseNumOfParticipants}>
              <Icon name="minus" />
            </Button>
            <Label basic size={"big"}>
              {numOfParticipantsInput}
            </Label>
            <Button icon onClick={onIncreaseNumOfParticipants}>
              <Icon name="plus" />
            </Button>
          </Button.Group>
        </Form.Field>
        <Form.Field>
          <label>{strings.newSessionDietTagsInputLabel.text()}</label>
          <Label.Group>
            {dietTags.map((dietTag) => {
              const isSelected = dietTagsInput.includes(dietTag);
              return (
                <Button
                  key={`NewSessionPage-DietTag-${dietTag}`}
                  size={"small"}
                  basic={!isSelected}
                  color={isSelected ? "green" : undefined}
                  onClick={
                    isSelected
                      ? () => onRemoveDietTag(dietTag)
                      : () => onAddDietTag(dietTag)
                  }
                  style={{ marginBottom: "3px" }}
                >
                  {dietTag}
                </Button>
              );
            })}
          </Label.Group>
        </Form.Field>
        <Form.Field>
          <label>{strings.newSessionCuisineTagInputLabel.text()}</label>
          <Label.Group>
            {cuisineTags.map((cuisineTag) => {
              const isSelected = cuisineTag === cuisineTagInput;
              return (
                <Button
                  key={`NewSessionPage-CuisineTag-${cuisineTag}`}
                  size={"small"}
                  basic={!isSelected}
                  color={isSelected ? "green" : undefined}
                  onClick={() => onSelectCuisineTag(cuisineTag)}
                  style={{ marginBottom: "3px" }}
                >
                  {cuisineTag}
                </Button>
              );
            })}
          </Label.Group>
        </Form.Field>
        <Button
          type={"submit"}
          color={"pink"}
          loading={isCreatingSession}
          onClick={onCreateSession}
        >
          {strings.start.text()}
        </Button>
      </Form>
    </PageLayout>
  );
};
