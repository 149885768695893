export const strings = {
  errorPageMessage: {
    text: () => `Something went wrong, stop trying to do bad stuff :(`,
    comment: "A generic message for an error page",
  },
  homePageHeader: {
    text: (appName: string) => `Welcome to ${appName}!`,
    comment: "The header message for the home page",
  },
  homePageFirstDescription: {
    text: () =>
      `Can't decide what to cook? This app will help you decide, and you can use it with others.`,
    comment: "The description of the app on the home page",
  },
  homePageNewSessionButton: {
    text: () => `Start a new session`,
    comment: "Text of a button to start a new recipe deciding session",
  },
  homePageJoinSessionButton: {
    text: () => `Join an existing session`,
    comment: "Text of a button to join an existing session",
  },
  homePageHowItWorksHeader: {
    text: () => `How it works`,
    comment: "Section header explaining how the site works",
  },
  joinPageHeader: {
    text: () => `Enter session code`,
    comment: "Header message of a page to join a session",
  },
  joinPageDescription: {
    text: () => `Enter the join code below`,
    comment: "Message instructing user to enter join code below",
  },
  joinCodeInputPlaceholder: {
    text: () => `H8JR3NQ`,
    comment: "A sample join code",
  },
  submit: {
    text: () => `Submit`,
    comment: "Text showing a user can submit something",
  },
  joinCodeNotFoundErrorTitle: {
    text: () => `Session not found`,
    comment: "An error message that no session was found with a join code",
  },
  joinCodeNotFoundErrorSubtitle: {
    text: () => `Make sure you entered the code correctly.`,
    comment: "An error message that suggests entering the code correctly",
  },
  newSessionHeader: {
    text: () => `Start a new session`,
    comment: "Header of a page to start a new recipe deciding session",
  },
  sessionLoadingMessage: {
    text: () => `Sautéing some dreams...`,
    comment: "A loading message for a session",
  },
  awaitingParticipantsPageHeader: {
    text: (numOfJoinedParticipants: number, numOfParticipants: number) =>
      `Waiting for others to join (${numOfJoinedParticipants}/${numOfParticipants})`,
    comment:
      "Header while awaiting for others to join a session, shows how many out of total have joined so far",
  },
  awaitingParticipantsPageCodeSubtitle: {
    text: () => `Others can join with this code`,
    message: "Let's others know they can join with the code above",
  },
  awaitingParticipantsPageCopyCodeButton: {
    text: () => `Copy code`,
    message: "Button text to copy a code",
  },
  inProgressPageReadyMinutesLabel: {
    text: (rdyInMutes: number) => `${rdyInMutes} minutes`,
    comment: "A label denoting how many minutes the recipe is ready in",
  },
  inProgressPageServingsLabel: {
    text: (servings: number) => `${servings} servings`,
    comment: "A label denoting how many servings the recipe creates",
  },
  vegetarian: {
    text: () => `Vegetarian`,
    comment: "Vegetarian",
  },
  vegan: {
    text: () => `Vegan`,
    comment: "Vegan",
  },
  dairyFree: {
    text: () => `Dairy Free`,
    comment: "Dairy free",
  },
  ingredients: {
    text: () => `Ingredients`,
    comment: "Ingredients",
  },
  instructions: {
    text: () => `Instructions`,
    comment: "Instructions",
  },
  fullRecipe: {
    text: () => `Full Recipe`,
    comment: "A header for a section with links to the full recipe",
  },
  joinInputPageHeader: {
    text: () => `Join the session`,
    comment: "A header for a page to join a session",
  },
  joinInputNameInputLabel: {
    text: () => `Enter a name that others will see you as`,
    comment: "A label for a name input",
  },
  joinInputNameInputPlaceholder: {
    text: () => `Johnny...`,
    comment: "Placeholder for a name input, it's a sample name",
  },
  join: {
    text: () => `Join`,
    comment: "Join",
  },
  joinedParticipantsNamesLabel: {
    text: () => `Who has joined:`,
    comment:
      "A label for a secion showing the names of who has joined a session",
  },
  joinedParticipantsNamesEmptyMessage: {
    text: () => `Nobody has joined this session yet`,
    comment:
      "A label explaining that no participants have joined a session yet",
  },
  sidebarCredits: {
    text: () => `Credits`,
    comment: "A label for a button that navigates to a credits page",
  },
  sidebarMySessions: {
    text: () => `My Sessions`,
    comment: "A label for a button that navigates to a my sessions page",
  },
  creditsPageHeader: {
    text: () => `Credits for the site`,
    comment: "A header for a page about credits for the site",
  },
  sessionOutOfRecipesMessage: {
    text: () =>
      `Looks like there's no more recipes to go over here. Create a new session and start over with brand new recipes!`,
    comment:
      "A message that there's no more recipes in the session and that the user should go create a new session",
  },
  sessionOutOfRecipesHint: {
    text: () =>
      `It's possible you added too many filters to the recipe selection. Try broadening your session next time.`,
    comment:
      "A message letting user know they may have selected too many tags and limited their recipes.",
  },
  mySessionsPageHeader: {
    text: () => `My sessions`,
    comment: "Header for a page about my sessions",
  },
  mySessionsEmptyStateMessage: {
    text: () => `You haven't been a part of any sessions`,
    comment: "A message that the user has no sessions",
  },
  sessionStateJoinInput: {
    text: () => `At join page`,
    comment: "A label about a session that's at the join input stage",
  },
  sessionStateAwaitingParticipants: {
    text: () => `Awaiting other participants`,
    comment:
      "A label about a session that's at the awaiting participants stage",
  },
  sessionStateInProgress: {
    text: () => `In progress`,
    comment: "A label about a session that's at the in progress stage",
  },
  sessionStateConsensusReached: {
    text: () => `Match found`,
    comment: "A label about a session that's at the match found stage",
  },
  newSessionNumOfParticipantsInputLabel: {
    text: () => `How many people are deciding what to cook?`,
    comment:
      "A label for an input to decide how many people are deciding what to cook",
  },
  newSessionDietTagsInputLabel: {
    text: () => `Any dietary restrictions? (optional)`,
    comment: "A label for an inputs to select dietary restrictions",
  },
  newSessionCuisineTagInputLabel: {
    text: () => `Any cuisine preference? (optional)`,
    comment: "A label for an inputs to select cuisine preference",
  },
  start: {
    text: () => `Start`,
    comment: "Start",
  },
  howItWorksStartTitle: {
    text: () => `Start a session`,
    comment: "Title for a step about starting a session",
  },
  howItWorksStartSubtitle: {
    text: () =>
      `Customize your recipe selections with diets, cuisines, and more`,
    comment: "Subtitle for a step about starting a session",
  },
  howItWorksJoinTitle: {
    text: () => `Join with others`,
    comment: "Title for a step about joining with others",
  },
  howItWorksJoinSubtitle: {
    text: () => `Get the gang together with your unique join code`,
    comment: "Subtitle for a step about joining with others",
  },
  howItWorksSwipeTitle: {
    text: () => `Swipe through recipes`,
    comment: "Title for a step about swiping through recipes",
  },
  howItWorksSwipeSubtitle: {
    text: () => `Like or dislike a feed of thousands of recipes`,
    comment: "Subtitle for a step about swiping through recipes",
  },
  howItWorksMatchTitle: {
    text: () => `Find a match`,
    comment: "Title for a step about finding a match",
  },
  howItWorksMatchSubtitle: {
    text: () => `If all participants like the same recipe, then it's a match!`,
    comment: "Subtitle for a step about finding a match",
  },
};
