import React from "react";
import { Label, SemanticCOLORS } from "semantic-ui-react";
import { SessionState } from "../../types";
import { strings } from "../../localization/strings";

interface ISessionStateLabelProps {
  sessionState: SessionState;
}

export const SessionStateLabel: React.FC<ISessionStateLabelProps> = (props) => {
  const { sessionState } = props;
  let text = "Unknown";
  let color: SemanticCOLORS = "black";

  switch (sessionState) {
    case SessionState.JoinInput:
      text = strings.sessionStateJoinInput.text();
      color = "black";
      break;
    case SessionState.AwaitingParticipants:
      text = strings.sessionStateAwaitingParticipants.text();
      color = "yellow";
      break;
    case SessionState.InProgress:
      text = strings.sessionStateInProgress.text();
      color = "teal";
      break;
    case SessionState.ConsensusReached:
      text = strings.sessionStateConsensusReached.text();
      color = "green";
      break;
  }

  return <Label color={color}>{text}</Label>;
};
