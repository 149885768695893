import React from "react";
import { PageLayout } from "../common/PageLayout";
import { PageHeader } from "../common/PageHeader";
import { strings } from "../../localization/strings";
import { List } from "semantic-ui-react";

interface ICreditsPageProps {}

export const CreditsPage: React.FC<ICreditsPageProps> = (props) => {
  return (
    <PageLayout style={{ lineBreak: "anywhere" }}>
      <PageHeader
        title={strings.creditsPageHeader.text()}
        enableGoBack={true}
      />
      <List size={"big"}>
        <List.Item>
          <List.Icon name="code" />
          <List.Content>{"Matthew Schmuckler"}</List.Content>
          <List.List>
            <List.Item>
              <List.Icon name="mail outline" />
              <List.Content>{"mschmuckler854@gmail.com"}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="github" />

              <List.Content>
                <a
                  href={"https://github.com/mschmuckler"}
                  target="_blank"
                  rel="noreferrer"
                >
                  {"https://github.com/mschmuckler"}
                </a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="linkedin" />

              <List.Content>
                <a
                  href={"https://www.linkedin.com/in/matthew-schmuckler/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  {"https://www.linkedin.com/in/matthew-schmuckler/"}
                </a>
              </List.Content>
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          <List.Icon name="chess rook" />
          <List.Content>
            {"Many of the icons by "}
            <a href={"https://icons8.com/"} target="_blank" rel="noreferrer">
              {"Icons8"}
            </a>
          </List.Content>
        </List.Item>
      </List>
    </PageLayout>
  );
};
