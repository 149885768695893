import React from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Sidebar } from "semantic-ui-react";
import { creditsPath, mySessionsPath } from "../../navigation/routePaths";
import { strings } from "../../localization/strings";

interface ISideBarProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

export const SideBar: React.FC<ISideBarProps> = (props) => {
  const { isVisible, setIsVisible } = props;
  return (
    <Sidebar
      as={Menu}
      animation={"overlay"}
      icon={"labeled"}
      onHide={() => setIsVisible(false)}
      vertical={true}
      inverted={true}
      direction={"right"}
      visible={isVisible}
    >
      <Link to={mySessionsPath.generateDynamicPath()}>
        <Menu.Item onClick={() => setIsVisible(false)}>
          <Icon name={"folder open"} />
          {strings.sidebarMySessions.text()}
        </Menu.Item>
      </Link>
      <Link to={creditsPath.generateDynamicPath()}>
        <Menu.Item onClick={() => setIsVisible(false)}>
          <Icon name={"code"} />
          {strings.sidebarCredits.text()}
        </Menu.Item>
      </Link>
    </Sidebar>
  );
};
