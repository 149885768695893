import React from "react";
import { useBreakpoint } from "../../utils/useBreakpoint";
import { Header } from "semantic-ui-react";
import burgerLoadingGif from "../../assets/burger-loading.gif";

interface IRecipeImageProps {
  title: string;
  image: string;
  bottomLeftButton?: JSX.Element;
  bottomRightButton?: JSX.Element;
  style?: React.CSSProperties;
}

export const RecipeImage: React.FC<IRecipeImageProps> = (props) => {
  const { title, image, bottomLeftButton, bottomRightButton, style } = props;
  const { isScreenLargerThan450 } = useBreakpoint();

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        display: "inline-block",
        marginBottom: "1rem",
        ...style,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <img
          key={image}
          src={image}
          style={{
            maxHeight: "400px",
            minHeight: "200px",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            borderRadius: "5px",
            background: `url(${burgerLoadingGif}) no-repeat center`,
          }}
          alt={title}
        />
      </div>
      <Header
        as={isScreenLargerThan450 ? "h1" : "h2"}
        style={{
          position: "absolute",
          top: "1rem",
          left: "1rem",
          right: "1rem",
          textShadow: "rgb(0 0 0) 2px 2px 4px",
          color: "white",
          margin: 0,
          padding: 0,
        }}
      >
        {title}
      </Header>
      {bottomLeftButton || null}
      {bottomRightButton || null}
    </div>
  );
};
