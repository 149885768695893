import React from "react";
import { HeaderBar } from "../components/header-bar/HeaderBar";
import { Outlet } from "react-router-dom";

interface IRootProps {}

export const Root: React.FC<IRootProps> = (props) => {
  return (
    <>
      <HeaderBar />
      <div
        style={{
          padding: "2rem",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        <Outlet />
      </div>
    </>
  );
};
