import {
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useMutation } from "@tanstack/react-query";
import { useUserAuth } from "../../auth/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { RecipeAcceptanceStatus } from "../../types";

export const useUpdateRecipeAcceptanceStatusMutation = () => {
  const { user } = useUserAuth();
  const updateRecipeAcceptanceStatus = async (vars: {
    sessionId: string;
    recipeId: string;
    recipeAcceptanceStatus: RecipeAcceptanceStatus;
  }) => {
    if (!user) {
      return Promise.reject(
        "useUpdateRecipeAcceptanceStatusMutation, error - no user found"
      );
    }
    const { sessionId, recipeId, recipeAcceptanceStatus } = vars;
    const db = getFirestore();
    const sessionDocRef = doc(db, collectionPaths.sessions, sessionId);
    const sessionUpdates: any = {
      updatedAt: serverTimestamp(),
    };
    sessionUpdates[`recipeAcceptanceStatuses.${user.uid}.${recipeId}`] =
      recipeAcceptanceStatus;
    await updateDoc(sessionDocRef, sessionUpdates);
    return Promise.resolve(vars);
  };
  return useMutation(updateRecipeAcceptanceStatus, {
    onSettled: settledLogger("useUpdateRecipeAcceptanceStatusMutation"),
  });
};
