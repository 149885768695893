import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { convertSessionSnapToISession } from "../firestore-doc-conversion/convertSessionSnapToISession";

export const getSessionQueryKey = (sessionId: string) => [
  "getSessionQueryKey",
  sessionId,
];

interface IGetSessionVars {
  sessionId: string;
}

const getSession = async (vars: IGetSessionVars) => {
  const { sessionId } = vars;
  const db = getFirestore();
  const sessionDocRef = doc(db, collectionPaths.sessions, sessionId);
  const snapshot = await getDoc(sessionDocRef);
  if (snapshot.exists()) {
    return convertSessionSnapToISession(sessionId, snapshot.data());
  } else {
    return Promise.reject("getSession, session not found");
  }
};

export const useGetSessionQuery = (
  vars: IGetSessionVars,
  enabled: boolean = true
) => {
  const { sessionId } = vars;
  return useQuery(getSessionQueryKey(sessionId), () => getSession(vars), {
    onSettled: settledLogger("useGetSessionQuery"),
    enabled,
  });
};

export const useGetSessionFetch = () => {
  const queryClient = useQueryClient();
  return (vars: IGetSessionVars) => {
    const { sessionId } = vars;
    return queryClient.fetchQuery(getSessionQueryKey(sessionId), () =>
      getSession(vars)
    );
  };
};
