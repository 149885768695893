export const APP_NAME = "ChefSwipe";

export const dietTags = ["vegetarian", "vegan", "paleo", "ketogenic"];
export const cuisineTags = [
  "american",
  "asian",
  "cajun",
  "french",
  "greek",
  "indian",
  "italian",
  "mexican",
  "middle eastern",
];
export const dishTypeTags = ["main course", "snack", "breakfast"];
