import React from "react";
import { ISession } from "../../../types";
import {
  findWinningConsensusRecipe,
  generateAcceptedRecipeListsByUser,
} from "../../../utils/utils";
import { ErrorPage } from "../../ErrorPage";
import { Card, Grid, List } from "semantic-ui-react";

interface ILikedRecipesByUserProps {
  session: ISession;
}

export const LikedRecipesByUser: React.FC<ILikedRecipesByUserProps> = (
  props
) => {
  const { session } = props;
  const winningRecipe = findWinningConsensusRecipe(session);
  const acceptedRecipeListsByUser = generateAcceptedRecipeListsByUser(session);

  if (!winningRecipe) {
    return <ErrorPage />;
  }

  return (
    <Grid container doubling stackable columns={4}>
      {Object.keys(acceptedRecipeListsByUser).map((uid) => {
        const acceptedUserRecipes = acceptedRecipeListsByUser[uid].recipes;
        const userName = acceptedRecipeListsByUser[uid].name;
        return (
          <Grid.Column key={`AcceptedRecipe-CardList-${uid}`}>
            <Card>
              <Card.Content header={`${userName}'s Liked Recipes`} />
              <Card.Content>
                <List>
                  {acceptedUserRecipes.map((acceptedRecipe) => {
                    const isWinningRecipe =
                      winningRecipe.id === acceptedRecipe.id;
                    return (
                      <List.Item
                        key={`AcceptedRecipe-ListItem-${acceptedRecipe.id}-${uid}`}
                      >
                        <List.Icon
                          name={isWinningRecipe ? "heart" : "heart outline"}
                          color={isWinningRecipe ? "green" : undefined}
                        />
                        <List.Content>
                          <a
                            href={acceptedRecipe.spoonacularSourceUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {acceptedRecipe.title}
                          </a>
                        </List.Content>
                      </List.Item>
                    );
                  })}
                </List>
              </Card.Content>
            </Card>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};
