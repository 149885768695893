import React, { useEffect, useState } from "react";
import { Icon, Step } from "semantic-ui-react";
import { semanticColors } from "../../styles/colors";
import { strings } from "../../localization/strings";

interface IHowItWorksStepsProps {}

export const HowItWorksSteps: React.FC<IHowItWorksStepsProps> = (props) => {
  const [currentActiveStep, setCurrentActiveStep] = useState(0);
  const loopThroughSteps = () => {
    setCurrentActiveStep((oldValue) => (oldValue === 3 ? 0 : oldValue + 1));
  };

  useEffect(() => {
    const intervalId = setInterval(loopThroughSteps, 1500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Step.Group vertical fluid>
      <Step active={currentActiveStep === 0}>
        <Icon
          name="plus"
          color={currentActiveStep === 0 ? "orange" : undefined}
        />
        <Step.Content>
          <Step.Title
            style={{
              color:
                currentActiveStep === 0 ? semanticColors.orange : undefined,
            }}
          >
            {strings.howItWorksStartTitle.text()}
          </Step.Title>
          <Step.Description>
            {strings.howItWorksStartSubtitle.text()}
          </Step.Description>
        </Step.Content>
      </Step>
      <Step active={currentActiveStep === 1}>
        <Icon
          name="handshake"
          color={currentActiveStep === 1 ? "pink" : undefined}
        />
        <Step.Content>
          <Step.Title
            style={{
              color: currentActiveStep === 1 ? semanticColors.pink : undefined,
            }}
          >
            {strings.howItWorksJoinTitle.text()}
          </Step.Title>
          <Step.Description>
            {strings.howItWorksJoinSubtitle.text()}
          </Step.Description>
        </Step.Content>
      </Step>
      <Step active={currentActiveStep === 2}>
        <Icon
          name="food"
          color={currentActiveStep === 2 ? "teal" : undefined}
        />
        <Step.Content>
          <Step.Title
            style={{
              color: currentActiveStep === 2 ? semanticColors.teal : undefined,
            }}
          >
            {strings.howItWorksSwipeTitle.text()}
          </Step.Title>
          <Step.Description>
            {strings.howItWorksSwipeSubtitle.text()}
          </Step.Description>
        </Step.Content>
      </Step>
      <Step active={currentActiveStep === 3}>
        <Icon
          name="heart"
          color={currentActiveStep === 3 ? "green" : undefined}
        />
        <Step.Content>
          <Step.Title
            style={{
              color: currentActiveStep === 3 ? semanticColors.green : undefined,
            }}
          >
            {strings.howItWorksMatchTitle.text()}
          </Step.Title>
          <Step.Description>
            {strings.howItWorksMatchSubtitle.text()}
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};
