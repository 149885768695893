import { DocumentData, Timestamp } from "firebase/firestore";
import { ISession } from "../../types";

export const convertSessionSnapToISession = (
  sessionId: string,
  documentData: DocumentData
): ISession => {
  const {
    numOfParticipants,
    participantIds,
    participants,
    recipeAcceptanceStatuses,
    recipes,
    tags,
    owner,
    createdAt,
    updatedAt,
  } = documentData;

  return {
    id: `${sessionId}`,
    numOfParticipants,
    participantIds,
    participants,
    recipeAcceptanceStatuses,
    recipes,
    tags,
    owner,
    createdAt: !!createdAt ? (createdAt as Timestamp).toDate() : new Date(), // Sometimes this field temporarily blinks out when updating
    updatedAt: !!updatedAt ? (updatedAt as Timestamp).toDate() : new Date(), // Sometimes this field temporarily blinks out when updating
  };
};
