import React, { useId } from "react";
import { List } from "semantic-ui-react";

interface IRecipeInredientsProps {
  ingredients: string[];
}

export const RecipeInredients: React.FC<IRecipeInredientsProps> = (props) => {
  const { ingredients } = props;
  const uniqueId = useId();

  return (
    <List>
      {ingredients.map((ingredient) => {
        return (
          <List.Item key={`RecipeIngredientsItem-${uniqueId}-${ingredient}`}>
            <List.Icon name="check square outline" />
            <List.Content>{ingredient}</List.Content>
          </List.Item>
        );
      })}
    </List>
  );
};
