import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "../components/ErrorPage";
import {
  creditsPath,
  homePath,
  joinSessionPath,
  mySessionsPath,
  newSessionPath,
  sessionPath,
} from "./routePaths";
import { HomePage } from "../components/home/HomePage";
import { NewSessionPage } from "../components/new-session/NewSessionPage";
import { SessionPage } from "../components/session/SessionPage";
import { Root } from "./Root";
import { JoinSessionPage } from "../components/join-session/JoinSessionPage";
import { CreditsPage } from "../components/credits/CreditsPage";
import { MySessionsPage } from "../components/my-sessions/MySessionsPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: homePath.staticPath,
        element: <HomePage />,
      },
      {
        path: newSessionPath.staticPath,
        element: <NewSessionPage />,
      },
      {
        path: sessionPath.staticPath,
        element: <SessionPage />,
      },
      {
        path: joinSessionPath.staticPath,
        element: <JoinSessionPage />,
      },
      {
        path: creditsPath.staticPath,
        element: <CreditsPage />,
      },
      {
        path: mySessionsPath.staticPath,
        element: <MySessionsPage />,
      },
    ],
  },
]);
