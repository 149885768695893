export interface ISession {
  id: string;
  numOfParticipants: number;
  participantIds: string[];
  participants: {
    [userId: string]: {
      name: string;
      status: SessionParticipantJoinStatus;
    };
  };
  recipeAcceptanceStatuses: {
    [userId: string]: {
      [recipeId: string]: RecipeAcceptanceStatus;
    };
  };
  recipes: IRecipe[];
  tags: string[];
  owner: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IRecipe {
  id: string;
  title: string;
  summary: string;
  spoonacularSourceUrl: string;
  image: string;
  instructionSteps: string; // html or raw string or blank string
  ingredients: string[]; // extendedIngredients[0].original
  dishTypes: string[];
  diets: string[];
  cuisines: string[];
  readyInMinutes: number;
  servings: number;
  isVegan: boolean;
  isVegetarian: boolean;
  isVeryHealthy: boolean;
  isVeryPopular: boolean;
  isDairyFree: boolean;
}

export enum RecipeAcceptanceStatus {
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export enum SessionParticipantJoinStatus {
  Joined = "Joined",
}

export enum SessionState {
  JoinInput = "JoinInput",
  AwaitingParticipants = "AwaitingParticipants",
  InProgress = "InProgress",
  ConsensusReached = "ConsensusReached",
}
