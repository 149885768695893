import React from "react";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../auth/useUserAuth";
import { useSessionSubscription } from "../../services/subscriptions/useSessionSubscription";
import { GenericLoaderPage } from "../GenericLoaderPage";
import { ErrorPage } from "../ErrorPage";
import { SessionState } from "../../types";
import { calculateSessionState } from "../../utils/utils";
import { AwaitingParticipantsPage } from "./awaiting-participants/AwaitingParticipantsPage";
import { InProgressPage } from "./in-progress/InProgressPage";
import { ConsensusReachedPage } from "./consensus-reached/ConsensusReachedPage";
import { JoinInputPage } from "./join-input/JoinInputPage";

interface ISessionPageProps {}

type ISessionPageParams = {
  sessionId: string;
};

export const SessionPage: React.FC<ISessionPageProps> = (props) => {
  const { sessionId } = useParams<ISessionPageParams>();
  const { user } = useUserAuth();
  const { session } = useSessionSubscription(sessionId || "");

  if (!sessionId) {
    return <ErrorPage />;
  }

  if (!session || !user || session.recipes.length === 0) {
    return <GenericLoaderPage />;
  }

  const sessionState = calculateSessionState(session, user?.uid);

  let content: JSX.Element | null = null;
  switch (sessionState) {
    case SessionState.JoinInput:
      content = <JoinInputPage session={session} />;
      break;
    case SessionState.AwaitingParticipants:
      content = <AwaitingParticipantsPage session={session} />;
      break;
    case SessionState.InProgress:
      content = <InProgressPage session={session} />;
      break;
    case SessionState.ConsensusReached:
      content = <ConsensusReachedPage session={session} />;
      break;
  }
  return content;
};
