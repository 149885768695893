import React from "react";
import { useUserAuth } from "../../auth/useUserAuth";
import { GenericLoaderPage } from "../GenericLoaderPage";
import { Card } from "semantic-ui-react";
import {
  calculateSessionState,
  findWinningConsensusRecipe,
  generateNamesOfJoinedParticipants,
} from "../../utils/utils";
import { ISession } from "../../types";
import { Link } from "react-router-dom";
import { sessionPath } from "../../navigation/routePaths";
import { ParticipantNameTags } from "../common/ParticipantNameTags";
import { SessionStateLabel } from "../common/SessionStateLabel";
import { RecipeImage } from "../common/RecipeImage";

interface IMySessionsCardProps {
  session: ISession;
}

export const MySessionsCard: React.FC<IMySessionsCardProps> = (props) => {
  const { session } = props;
  const { user } = useUserAuth();

  if (!user) {
    return <GenericLoaderPage />;
  }

  const sessionState = calculateSessionState(session, user.uid);
  const winningRecipe = findWinningConsensusRecipe(session);
  const joinedNames = generateNamesOfJoinedParticipants(session);

  return (
    <Link
      to={sessionPath.generateDynamicPath(session.id)}
      style={{ color: "black", marginBottom: "2rem" }}
    >
      <Card fluid>
        <Card.Content>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>{session.createdAt?.toLocaleDateString() || ""}</p>
            <p>{session.id}</p>
          </div>
          <ParticipantNameTags names={joinedNames} />
        </Card.Content>
        <Card.Content>
          <SessionStateLabel sessionState={sessionState} />
          {!!winningRecipe && (
            <RecipeImage
              title={winningRecipe.title}
              image={winningRecipe.image}
              style={{ marginTop: "1rem" }}
            />
          )}
        </Card.Content>
      </Card>
    </Link>
  );
};
