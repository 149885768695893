import React from "react";

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = React.useState(1200);
  const resize = () => {
    setBreakpoint(window.innerWidth);
  };

  React.useEffect(() => {
    resize();
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return {
    isScreenLargerThan450: breakpoint > 450,
  };
};
