import {
  IRecipe,
  ISession,
  RecipeAcceptanceStatus,
  SessionParticipantJoinStatus,
  SessionState,
} from "../types";

export const findRecipeInSession = (
  session: ISession,
  recipeId: string
): IRecipe => {
  return session.recipes.filter(
    (recipe) => `${recipe.id}` === `${recipeId}`
  )[0];
};

export const calculateSessionState = (
  session: ISession,
  meUserId: string
): SessionState => {
  // Determine if meUser needs to join the session
  const isMeJoined = isMeJoinedToSession(session, meUserId);
  if (!isMeJoined) {
    return SessionState.JoinInput;
  }

  // Determine if session needs more participants
  const numOfJoinedParticipants = calculateNumOfJoinedParticipants(session);
  if (numOfJoinedParticipants < session.numOfParticipants) {
    return SessionState.AwaitingParticipants;
  }

  // Determine if session has reached a consensus and is complete
  const recipeAcceptsDict = calculateSessionRecipeConsensus(session);
  const isAnyRecipeAcceptedByAll = !!Object.values(recipeAcceptsDict).filter(
    (numOfAccepts) => numOfAccepts >= session.numOfParticipants
  ).length;
  if (isAnyRecipeAcceptedByAll) {
    return SessionState.ConsensusReached;
  }

  return SessionState.InProgress;
};

/*
 * Returns a map of recipes with the number of agreements on each
 **/
export const calculateSessionRecipeConsensus = (
  session: ISession
): { [recipeId: string]: number } => {
  const recipeAcceptDict: { [recipeId: string]: number } = {};

  Object.keys(session.recipeAcceptanceStatuses).map((uid) => {
    const userVotedRecipes = session.recipeAcceptanceStatuses[uid];
    return Object.keys(userVotedRecipes).forEach((recipeId) => {
      if (userVotedRecipes[recipeId] === RecipeAcceptanceStatus.Accepted) {
        if (recipeAcceptDict[recipeId] === undefined) {
          recipeAcceptDict[recipeId] = 1;
        } else {
          recipeAcceptDict[recipeId] = recipeAcceptDict[recipeId] + 1;
        }
      }
    });
  });

  return recipeAcceptDict;
};

export const calculateNumOfJoinedParticipants = (session: ISession): number => {
  return Object.keys(session.participants).filter(
    (uid) =>
      session.participants[uid].status === SessionParticipantJoinStatus.Joined
  ).length;
};

export const generateNamesOfJoinedParticipants = (session: ISession) => {
  const joinedParticipantNames: string[] = [];
  Object.keys(session.participants).forEach((uid) => {
    if (
      session.participants[uid].status === SessionParticipantJoinStatus.Joined
    ) {
      joinedParticipantNames.push(session.participants[uid].name);
    }
  });
  return joinedParticipantNames;
};

export const filterOutAlreadyVotedRecipes = (
  session: ISession,
  meUserId: string
): IRecipe[] => {
  const meAlreadyVotedRecipeIds: string[] = [];

  const meRecipeAcceptanceStatuses = session.recipeAcceptanceStatuses[meUserId];
  if (meRecipeAcceptanceStatuses) {
    Object.keys(meRecipeAcceptanceStatuses).forEach((recipeId) => {
      meAlreadyVotedRecipeIds.push(`${recipeId}`);
    });
  }

  return session.recipes.filter(
    (recipe) => !meAlreadyVotedRecipeIds.includes(`${recipe.id}`)
  );
};

export const isMeJoinedToSession = (
  session: ISession,
  meUserId: string
): boolean => {
  return (
    session.participants[meUserId] &&
    session.participants[meUserId].status ===
      SessionParticipantJoinStatus.Joined
  );
};

export const findWinningConsensusRecipe = (
  session: ISession
): IRecipe | undefined => {
  const recipeConsensusDict = calculateSessionRecipeConsensus(session);
  const winningRecipeId = Object.keys(recipeConsensusDict).filter(
    (recipeId) => recipeConsensusDict[recipeId] >= session.numOfParticipants
  )[0];

  if (winningRecipeId) {
    return findRecipeInSession(session, winningRecipeId);
  }

  return;
};

export const generateAcceptedRecipeListsByUser = (session: ISession) => {
  const acceptedRecipeListsByUser: {
    [userId: string]: { name: string; recipes: IRecipe[] };
  } = {};

  Object.keys(session.recipeAcceptanceStatuses).map((uid) => {
    acceptedRecipeListsByUser[uid] = {
      name: calculateNameByUidInSession(session, uid),
      recipes: [],
    };
    const userVotedRecipes = session.recipeAcceptanceStatuses[uid];
    return Object.keys(userVotedRecipes).forEach((recipeId) => {
      if (userVotedRecipes[recipeId] === RecipeAcceptanceStatus.Accepted) {
        acceptedRecipeListsByUser[uid].recipes = acceptedRecipeListsByUser[
          uid
        ].recipes.concat([findRecipeInSession(session, recipeId)]);
      }
    });
  });

  return acceptedRecipeListsByUser;
};

export const calculateNameByUidInSession = (session: ISession, uid: string) => {
  let name = "?NotFound?";
  Object.keys(session.participants).forEach((participantUid) => {
    if (uid === participantUid) {
      name = session.participants[uid].name;
    }
  });
  return name;
};
