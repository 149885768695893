import React from "react";
import { Header, Icon } from "semantic-ui-react";
import { PageLayout } from "./common/PageLayout";
import { strings } from "../localization/strings";

interface IGenericLoaderPageProps {
  title?: string;
}

export const GenericLoaderPage: React.FC<IGenericLoaderPageProps> = (props) => {
  const { title } = props;
  return (
    <PageLayout
      style={{
        alignItems: "center",
        justifyContent: "center",
        minHeight: "60vh",
      }}
    >
      <Header as={"h3"}>{title || strings.sessionLoadingMessage.text()}</Header>
      <Icon loading={true} size={"huge"} name={"food"} color={"pink"} />
    </PageLayout>
  );
};
