import React from "react";
import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  User,
} from "firebase/auth";

export const useUserAuth = () => {
  const auth = getAuth();
  const [user, setUser] = React.useState<User | null>(null);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        console.log(`useUserAuth.onAuthStateChanged, found user`, user);
      } else {
        setUser(null);
        console.log(`useUserAuth.onAuthStateChanged, no user found`);
      }
    });

    return unsubscribe;
  }, [auth]);

  const anonSignIn = async () => {
    try {
      const anonUser = await signInAnonymously(auth);
      console.log(`useUserAuth.anonSignIn, success`, anonUser);
    } catch (error) {
      console.log(`useUserAuth.anonSignIn, error`, error);
    }
  };

  return {
    user,
    anonSignIn,
  };
};
