import React from "react";
import { PageLayout } from "../common/PageLayout";
import { PageHeader } from "../common/PageHeader";
import { useListSessionsQuery } from "../../services/queries/useListSessionsQuery";
import { useUserAuth } from "../../auth/useUserAuth";
import { strings } from "../../localization/strings";
import { GenericLoaderPage } from "../GenericLoaderPage";
import { Header } from "semantic-ui-react";
import { MySessionsCard } from "./MySessionCard";

interface IMySessionsPageProps {}

export const MySessionsPage: React.FC<IMySessionsPageProps> = (props) => {
  const { user } = useUserAuth();
  const { data: mySessions } = useListSessionsQuery({
    participantId: user?.uid,
  });

  if (!mySessions || !user) {
    return <GenericLoaderPage />;
  }

  return (
    <PageLayout>
      <PageHeader
        title={strings.mySessionsPageHeader.text()}
        enableGoBack={true}
      />
      <>
        {mySessions.length === 0 && (
          <Header>{strings.mySessionsEmptyStateMessage.text()}</Header>
        )}
      </>
      <>
        {mySessions.map((session, index) => {
          return (
            <MySessionsCard
              key={`MySessionCard-${index}-${session.id}`}
              session={session}
            />
          );
        })}
      </>
    </PageLayout>
  );
};
