export const localStorageUtils = {
  myParticipantName: {
    get: () => {
      return localStorage.getItem("myParticipantName");
    },
    set: (name: string) => {
      localStorage.setItem("myParticipantName", name);
    },
  },
};
