export const generateNewSessionId = () => {
  const characters = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
  const length = 7;
  let id = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters[randomIndex];
  }

  return id;
};
