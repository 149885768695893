import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Message } from "semantic-ui-react";
import { PageLayout } from "../common/PageLayout";
import { strings } from "../../localization/strings";
import { PageHeader } from "../common/PageHeader";
import { useGetSessionFetch } from "../../services/queries/useGetSessionQuery";
import { sessionPath } from "../../navigation/routePaths";

interface IJoinSessionPageProps {}

export const JoinSessionPage: React.FC<IJoinSessionPageProps> = (props) => {
  const fetchSession = useGetSessionFetch();
  const navigate = useNavigate();
  const [joinCodeInput, setJoinCodeInput] = useState<string>("");
  const [isFetchingSession, setIsFetchingSession] = useState<boolean>(false);
  const [showSessionNotFoundError, setShowSessionNotFoundError] =
    useState<boolean>(false);

  const onJoinCodeInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value;
    newValue = newValue.toUpperCase();
    newValue = newValue.trim();
    setJoinCodeInput(newValue);
  };

  const onSubmit = async () => {
    setIsFetchingSession(true);
    setShowSessionNotFoundError(false);
    try {
      const session = await fetchSession({ sessionId: joinCodeInput });
      navigate(sessionPath.generateDynamicPath(session.id));
    } catch (error) {
      setIsFetchingSession(false);
      setShowSessionNotFoundError(true);
    }
  };

  return (
    <PageLayout>
      <PageHeader title={strings.joinPageHeader.text()} enableGoBack={true} />
      <Form>
        <Form.Field>
          <label>{strings.joinPageDescription.text()}</label>
          <input
            placeholder={strings.joinCodeInputPlaceholder.text()}
            onChange={onJoinCodeInputChange}
            value={joinCodeInput}
          />
        </Form.Field>
        <Button
          type={"submit"}
          color={"pink"}
          loading={isFetchingSession}
          onClick={onSubmit}
        >
          {strings.submit.text()}
        </Button>
      </Form>
      <>
        {showSessionNotFoundError && (
          <Message
            error={true}
            header={strings.joinCodeNotFoundErrorTitle.text()}
            content={strings.joinCodeNotFoundErrorSubtitle.text()}
          />
        )}
      </>
    </PageLayout>
  );
};
