import React from "react";
import { Icon, Label } from "semantic-ui-react";
import { IRecipe } from "../../types";
import { strings } from "../../localization/strings";

interface IRecipeTagsProps {
  recipe: IRecipe;
}

export const RecipeTags: React.FC<IRecipeTagsProps> = (props) => {
  const { recipe } = props;

  return (
    <Label.Group>
      <Label basic>
        <Icon name={"clock"} />
        {strings.inProgressPageReadyMinutesLabel.text(recipe.readyInMinutes)}
      </Label>
      <Label basic>
        <Icon name={"users"} />
        {strings.inProgressPageServingsLabel.text(recipe.servings)}
      </Label>
      {recipe.isVegetarian && (
        <Label basic>
          <Icon name={"leaf"} />
          {strings.vegetarian.text()}
        </Label>
      )}
      {recipe.isVegan && (
        <Label basic>
          <Icon name={"leaf"} />
          {strings.vegan.text()}
        </Label>
      )}
      {recipe.isDairyFree && (
        <Label basic>
          <Icon name={"paw"} />
          {strings.dairyFree.text()}
        </Label>
      )}
    </Label.Group>
  );
};
