import React, { useId } from "react";
import { Icon, Label } from "semantic-ui-react";

interface IParticipantNameTagsProps {
  names: string[];
}

export const ParticipantNameTags: React.FC<IParticipantNameTagsProps> = (
  props
) => {
  const { names } = props;
  const uniqueId = useId();

  return (
    <Label.Group>
      {names.map((name) => {
        return (
          <Label basic key={`Name-${name}-${uniqueId}`}>
            <Icon name={"user outline"} color="green" />
            {name}
          </Label>
        );
      })}
    </Label.Group>
  );
};
