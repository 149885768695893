import React, { useState } from "react";
import { PageLayout } from "../../common/PageLayout";
import { PageHeader } from "../../common/PageHeader";
import { ISession, SessionParticipantJoinStatus } from "../../../types";
import { Button, Form, Icon } from "semantic-ui-react";
import { useUpdateSessionParticipantJoinStatusMutation } from "../../../services/mutations/useUpdateSessionParticipantJoinStatusMutation";
import { strings } from "../../../localization/strings";
import { localStorageUtils } from "../../../utils/localStorageUtils";

interface IJoinInputPageProps {
  session: ISession;
}

export const JoinInputPage: React.FC<IJoinInputPageProps> = (props) => {
  const { session } = props;
  const { mutate: updateSessionParticipantJoinStatus } =
    useUpdateSessionParticipantJoinStatusMutation();
  const [nameInput, setNameInput] = useState<string>(
    localStorageUtils.myParticipantName.get() || ""
  );

  const onNameInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value;
    newValue = newValue.trim();
    if (newValue.length > 15) {
      return;
    }

    setNameInput(newValue);
  };

  const onJoinSession = () => {
    localStorageUtils.myParticipantName.set(nameInput);
    updateSessionParticipantJoinStatus({
      sessionId: session.id,
      name: nameInput,
      sessionParticipantJoinStatus: SessionParticipantJoinStatus.Joined,
    });
  };

  return (
    <PageLayout>
      <PageHeader title={strings.joinInputPageHeader.text()} />
      <Form>
        <Form.Field>
          <label>{strings.joinInputNameInputLabel.text()}</label>
          <input
            onChange={onNameInputChange}
            value={nameInput}
            placeholder={strings.joinInputNameInputPlaceholder.text()}
          />
        </Form.Field>
        <div>
          <Button
            color={"pink"}
            icon
            labelPosition="left"
            onClick={onJoinSession}
          >
            <Icon name={"handshake"} />
            {strings.join.text()}
          </Button>
        </div>
      </Form>
    </PageLayout>
  );
};
