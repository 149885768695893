import { setDoc, getFirestore, serverTimestamp, doc } from "firebase/firestore";
import { useMutation } from "@tanstack/react-query";
import { useUserAuth } from "../../auth/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { generateNewSessionId } from "../generateNewSessionId";

export const useCreateSessionMutation = () => {
  const { user } = useUserAuth();
  const createSession = async (vars: {
    numOfParticipants: number;
    tags: string[];
  }) => {
    if (!user) {
      console.error(`useCreateSessionMutation, no user found`);
      return Promise.reject();
    }
    const { numOfParticipants, tags } = vars;
    const newSessionId = generateNewSessionId();
    const newSession = {
      numOfParticipants,
      participantIds: [],
      participants: {},
      recipeAcceptanceStatuses: {},
      recipes: [],
      tags,
      owner: user.uid,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };
    const db = getFirestore();
    await setDoc(doc(db, collectionPaths.sessions, newSessionId), newSession);
    return Promise.resolve(newSessionId);
  };
  return useMutation(createSession, {
    onSettled: settledLogger("useCreateSessionMutation"),
  });
};
