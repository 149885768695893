import {
  arrayUnion,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserAuth } from "../../auth/useUserAuth";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { SessionParticipantJoinStatus } from "../../types";
import { listSessionsQueryKeySimple } from "../queries/useListSessionsQuery";

export const useUpdateSessionParticipantJoinStatusMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useUserAuth();
  const updateSessionParticipantJoinStatus = async (vars: {
    sessionId: string;
    name: string;
    sessionParticipantJoinStatus: SessionParticipantJoinStatus;
  }) => {
    if (!user) {
      return Promise.reject(
        "useUpdateSessionParticipantJoinStatusMutation, error - no user found"
      );
    }
    const { sessionId, name, sessionParticipantJoinStatus } = vars;
    const db = getFirestore();
    const sessionDocRef = doc(db, collectionPaths.sessions, sessionId);
    const sessionUpdates: any = {
      updatedAt: serverTimestamp(),
    };
    sessionUpdates[`participants.${user.uid}.name`] = name;
    sessionUpdates[`participants.${user.uid}.status`] =
      sessionParticipantJoinStatus;
    sessionUpdates.participantIds = arrayUnion(user.uid);
    await updateDoc(sessionDocRef, sessionUpdates);
    return Promise.resolve(vars);
  };
  return useMutation(updateSessionParticipantJoinStatus, {
    onSettled: settledLogger("useUpdateSessionParticipantJoinStatusMutation"),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [listSessionsQueryKeySimple],
      });
    },
  });
};
