import React, { useEffect, useState } from "react";
import { PageLayout } from "../../common/PageLayout";
import { ISession, RecipeAcceptanceStatus } from "../../../types";
import { Button, Header, Icon, Message, Transition } from "semantic-ui-react";
import { filterOutAlreadyVotedRecipes } from "../../../utils/utils";
import { useUserAuth } from "../../../auth/useUserAuth";
import { useUpdateRecipeAcceptanceStatusMutation } from "../../../services/mutations/useUpdateRecipeAcceptanceStatusMutation";
import { strings } from "../../../localization/strings";
import { RecipeImage } from "../../common/RecipeImage";
import { RecipeInredients } from "../../common/RecipeIngredients";
import { RecipeTags } from "../../common/RecipeTags";

interface IInProgressPageProps {
  session: ISession;
}

export const InProgressPage: React.FC<IInProgressPageProps> = (props) => {
  const { session } = props;
  const { user } = useUserAuth();
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(true);
  const { mutate: updateRecipeAcceptanceStatus } =
    useUpdateRecipeAcceptanceStatusMutation();
  const nonVotedOnRecipes = filterOutAlreadyVotedRecipes(
    session,
    user?.uid || ""
  );
  const currentRecipe = nonVotedOnRecipes[0];

  useEffect(() => {
    if (currentRecipe?.id) {
      window.scrollTo({ left: 0, top: 0 });
    }
  }, [currentRecipe?.id]);

  if (!currentRecipe) {
    return (
      <PageLayout>
        <Message header={strings.sessionOutOfRecipesMessage.text()} />
        <Message info content={strings.sessionOutOfRecipesHint.text()} />
      </PageLayout>
    );
  }

  const onUpdateRecipeStatusForMe = (status: RecipeAcceptanceStatus) => {
    setShouldAnimate(false);
    updateRecipeAcceptanceStatus(
      {
        sessionId: session.id,
        recipeId: currentRecipe.id,
        recipeAcceptanceStatus: status,
      },
      {
        onSuccess: () => setShouldAnimate(true),
      }
    );
  };

  return (
    <PageLayout>
      <Transition
        visible={shouldAnimate}
        animation={"horizontal flip"}
        duration={500}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            maxWidth: "800px",
            alignSelf: "center",
          }}
        >
          <RecipeImage
            title={currentRecipe.title}
            image={currentRecipe.image}
            bottomLeftButton={
              <Button
                icon
                onClick={() =>
                  onUpdateRecipeStatusForMe(RecipeAcceptanceStatus.Rejected)
                }
                style={{ backgroundColor: "transparent" }}
              >
                <Icon
                  circular={true}
                  name={"x"}
                  size={"big"}
                  color={"red"}
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "10%",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    backgroundColor: "white",
                    opacity: 1,
                  }}
                />
              </Button>
            }
            bottomRightButton={
              <Button
                icon
                onClick={() =>
                  onUpdateRecipeStatusForMe(RecipeAcceptanceStatus.Accepted)
                }
                style={{ backgroundColor: "transparent" }}
              >
                <Icon
                  circular={true}
                  name={"heart"}
                  size={"big"}
                  color={"green"}
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "10%",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    backgroundColor: "white",
                    opacity: 1,
                  }}
                />
              </Button>
            }
          />
          <RecipeTags recipe={currentRecipe} />
          <Header as={"h2"} style={{ marginBottom: 0 }}>
            {strings.ingredients.text()}
          </Header>
          <RecipeInredients ingredients={currentRecipe.ingredients} />
          <Header as={"h2"} style={{ marginBottom: 0 }}>
            {strings.instructions.text()}
          </Header>
          <div
            dangerouslySetInnerHTML={{ __html: currentRecipe.instructionSteps }}
          />
          <Header as={"h2"} style={{ marginBottom: 0 }}>
            {strings.fullRecipe.text()}
          </Header>
          <a
            href={currentRecipe.spoonacularSourceUrl}
            target="_blank"
            rel="noreferrer"
          >
            {currentRecipe.spoonacularSourceUrl}
          </a>
        </div>
      </Transition>
    </PageLayout>
  );
};
