import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useQuery } from "@tanstack/react-query";
import { collectionPaths } from "../collectionPaths";
import { settledLogger } from "../settledLogger";
import { convertSessionSnapToISession } from "../firestore-doc-conversion/convertSessionSnapToISession";

interface IListSessionsVars {
  participantId?: string;
}

export const listSessionsQueryKeySimple = "listSessionsQueryKey";

export const listSessionsQueryKey = (vars: IListSessionsVars) => [
  listSessionsQueryKeySimple,
  vars,
];

const listSessions = async (vars: IListSessionsVars) => {
  const { participantId } = vars;
  const db = getFirestore();
  const sessionsCollectionRef = collection(db, collectionPaths.sessions);
  const queryConstraints = [];
  // Sort by createdAt by default
  queryConstraints.push(orderBy("createdAt", "desc"));
  if (participantId) {
    queryConstraints.push(
      where("participantIds", "array-contains", participantId)
    );
  }
  const q = query(sessionsCollectionRef, ...queryConstraints, limit(10));
  const qSnapshot = await getDocs(q);
  const sessions = qSnapshot.docs.map((snapshot) =>
    convertSessionSnapToISession(snapshot.id, snapshot.data())
  );
  return sessions || [];
};

export const useListSessionsQuery = (
  vars: IListSessionsVars,
  enabled: boolean = true
) => {
  return useQuery(listSessionsQueryKey(vars), () => listSessions(vars), {
    onSettled: settledLogger("useListSessionsQuery"),
    enabled,
  });
};
