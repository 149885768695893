import React from "react";

interface IPageLayoutProps {
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
}

export const PageLayout: React.FC<IPageLayoutProps> = (props) => {
  const { children, style } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
