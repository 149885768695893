import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { homePath } from "../../navigation/routePaths";
import { SideBar } from "../side-bar/SideBar";
import { ParticipantNameTags } from "../common/ParticipantNameTags";
import { useSessionSubscription } from "../../services/subscriptions/useSessionSubscription";
import { generateNamesOfJoinedParticipants } from "../../utils/utils";

interface IHeaderBarProps {}

type IHeaderBarParams = {
  sessionId?: string;
};

export const HeaderBar: React.FC<IHeaderBarProps> = (props) => {
  const { sessionId } = useParams<IHeaderBarParams>();
  const { session } = useSessionSubscription(sessionId || "");
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  let joinedNames: string[] = [];
  if (session) {
    joinedNames = generateNamesOfJoinedParticipants(session);
  }
  const isJoinedNamesVisible =
    !!sessionId && !!session && joinedNames.length > 0;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: isJoinedNamesVisible
              ? "1px solid #e9e9e9"
              : undefined,
          }}
        >
          <Link to={homePath.generateDynamicPath()}>
            <Button icon={true} basic style={{ boxShadow: "none" }}>
              <Icon name={"home"} size={"large"} color={"black"} />
            </Button>
          </Link>
          <>
            {!!sessionId && (
              <p
                style={{ margin: 0, fontSize: "0.9rem" }}
              >{`CURRENT SESSION: ${sessionId}`}</p>
            )}
          </>
          <Button icon={true} basic style={{ boxShadow: "none" }}>
            <Icon
              name={"bars"}
              size={"large"}
              color={"black"}
              onClick={() => setIsSidebarVisible(true)}
            />
          </Button>
        </div>
        {isJoinedNamesVisible && (
          <div style={{ padding: "0.5rem 1rem 0rem 1rem" }}>
            <ParticipantNameTags names={joinedNames} />
          </div>
        )}
      </div>
      <SideBar
        isVisible={isSidebarVisible}
        setIsVisible={setIsSidebarVisible}
      />
    </>
  );
};
