import React from "react";
import { PageLayout } from "../../common/PageLayout";
import { PageHeader } from "../../common/PageHeader";
import { ISession } from "../../../types";
import { Label, Message } from "semantic-ui-react";
import { calculateNumOfJoinedParticipants } from "../../../utils/utils";
import { strings } from "../../../localization/strings";

interface IAwaitingParticipantsPageProps {
  session: ISession;
}

export const AwaitingParticipantsPage: React.FC<
  IAwaitingParticipantsPageProps
> = (props) => {
  const { session } = props;
  const numOfJoinedParticipants = calculateNumOfJoinedParticipants(session);

  return (
    <PageLayout>
      <PageHeader
        title={strings.awaitingParticipantsPageHeader.text(
          numOfJoinedParticipants,
          session.numOfParticipants
        )}
      />
      <Message
        icon={"share alternate"}
        header={
          <Label color={"pink"} basic size={"huge"}>
            {session.id}
          </Label>
        }
        content={strings.awaitingParticipantsPageCodeSubtitle.text()}
        attached={true}
      />
    </PageLayout>
  );
};
