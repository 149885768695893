import React from "react";
import { Link } from "react-router-dom";
import { joinSessionPath, newSessionPath } from "../../navigation/routePaths";
import { PageLayout } from "../common/PageLayout";
import { strings } from "../../localization/strings";
import { APP_NAME } from "../../constants";
import { Button, Header, Icon } from "semantic-ui-react";
import { PageHeader } from "../common/PageHeader";
import { HowItWorksSteps } from "./HowItWorksSteps";
import { useBreakpoint } from "../../utils/useBreakpoint";

interface IHomePageProps {}

export const HomePage: React.FC<IHomePageProps> = (props) => {
  const { isScreenLargerThan450 } = useBreakpoint();
  return (
    <PageLayout>
      <PageHeader title={strings.homePageHeader.text(APP_NAME)} />
      <p>{strings.homePageFirstDescription.text()}</p>
      <Link
        to={newSessionPath.generateDynamicPath()}
        style={{ marginBottom: "1rem" }}
      >
        <Button
          icon
          fluid={!isScreenLargerThan450}
          labelPosition={"left"}
          color={"pink"}
          size={"large"}
        >
          <Icon name={"plus"} />
          {strings.homePageNewSessionButton.text()}
        </Button>
      </Link>
      <Link to={joinSessionPath.generateDynamicPath()}>
        <Button
          icon
          fluid={!isScreenLargerThan450}
          labelPosition={"left"}
          color={"pink"}
          size={"large"}
          basic
        >
          <Icon name={"handshake outline"} />
          {strings.homePageJoinSessionButton.text()}
        </Button>
      </Link>
      <Header as={"h2"} style={{ marginBottom: 0, marginTop: "3rem" }}>
        {strings.homePageHowItWorksHeader.text()}
      </Header>
      <HowItWorksSteps />
    </PageLayout>
  );
};
