import React, { useEffect } from "react";
import { PageLayout } from "../../common/PageLayout";
import { PageHeader } from "../../common/PageHeader";
import { ISession } from "../../../types";
import { findWinningConsensusRecipe } from "../../../utils/utils";
import { ErrorPage } from "../../ErrorPage";
import { Divider, Header, Icon } from "semantic-ui-react";
import { RecipeImage } from "../../common/RecipeImage";
import { RecipeTags } from "../../common/RecipeTags";
import { strings } from "../../../localization/strings";
import { RecipeInredients } from "../../common/RecipeIngredients";
import { LikedRecipesByUser } from "./LikedRecipesByUser";

interface IConsensusReachedPageProps {
  session: ISession;
}

export const ConsensusReachedPage: React.FC<IConsensusReachedPageProps> = (
  props
) => {
  const { session } = props;
  const winningRecipe = findWinningConsensusRecipe(session);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, []);

  if (!winningRecipe) {
    return <ErrorPage />;
  }

  return (
    <PageLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Icon
          name={"heart"}
          color={"pink"}
          size={"big"}
          className={"pulsing-animation-1"}
        />
        <PageHeader title={"It's a match!"} style={{ margin: "1rem" }} />
        <Icon
          name={"heart"}
          color={"pink"}
          size={"big"}
          className={"pulsing-animation-2"}
        />
      </div>
      <RecipeImage title={winningRecipe.title} image={winningRecipe.image} />
      <RecipeTags recipe={winningRecipe} />
      <div style={{ marginTop: "1rem" }} />
      <LikedRecipesByUser session={session} />
      <Divider />
      <Header as={"h2"} style={{ marginBottom: 0, marginTop: 0 }}>
        {strings.ingredients.text()}
      </Header>
      <RecipeInredients ingredients={winningRecipe.ingredients} />
      <Header as={"h2"} style={{ marginBottom: 0 }}>
        {strings.instructions.text()}
      </Header>
      <div
        dangerouslySetInnerHTML={{ __html: winningRecipe.instructionSteps }}
      />
      <Header as={"h2"} style={{ marginBottom: 0 }}>
        {strings.fullRecipe.text()}
      </Header>
      <a
        href={winningRecipe.spoonacularSourceUrl}
        target="_blank"
        rel="noreferrer"
      >
        {winningRecipe.spoonacularSourceUrl}
      </a>
    </PageLayout>
  );
};
