import React from "react";
import { Link } from "react-router-dom";
import { useGoBack } from "../../navigation/navUtils";

interface IGoBackLinkProps {
  children: JSX.Element | JSX.Element[];
}

export const GoBackLink: React.FC<IGoBackLinkProps> = (props) => {
  const { children } = props;
  const goBack = useGoBack();
  return (
    <Link
      to={".."}
      onClick={(e) => {
        e.preventDefault();
        goBack();
      }}
    >
      {children}
    </Link>
  );
};
