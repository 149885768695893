import React from "react";
import { Header, Icon } from "semantic-ui-react";
import { GoBackLink } from "./GoBackLink";

interface IPageHeaderProps {
  title: string;
  style?: React.CSSProperties;
  enableGoBack?: boolean;
}

export const PageHeader: React.FC<IPageHeaderProps> = (props) => {
  const { title, style, enableGoBack } = props;

  return (
    <Header style={style} as={"h1"}>
      {enableGoBack && (
        <GoBackLink>
          <Icon name={"arrow left"} color={"black"} />
        </GoBackLink>
      )}
      <Header.Content>{title}</Header.Content>
    </Header>
  );
};
