export const homePath = {
  staticPath: "/",
  generateDynamicPath: () => `/`,
};

export const newSessionPath = {
  staticPath: "/newSession",
  generateDynamicPath: () => `/newSession`,
};

export const sessionPath = {
  staticPath: "/session/:sessionId",
  generateDynamicPath: (sessionId: string) => `/session/${sessionId}`,
};

export const joinSessionPath = {
  staticPath: "/joinSession",
  generateDynamicPath: () => `/joinSession`,
};

export const creditsPath = {
  staticPath: "/credits",
  generateDynamicPath: () => `/credits`,
};

export const mySessionsPath = {
  staticPath: "/mySessions",
  generateDynamicPath: () => `/mySessions`,
};
